import { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { useRecoilState } from "recoil";
import * as yup from "yup";

import useToast from "@/hooks/useToast";
import { userSelector } from "@/stores/selectors/userSelector";
import { User } from "@/types/user";
import { apiPatch } from "@/utils/api";

type EditModalProps = {
  setIsEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCompleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type Form = {
  first_name: string;
  last_name: string;
  first_name_kana: string;
  last_name_kana: string;
  phone_number: string;
  gender: number;
  birthday: string;
  postal_code: string;
};

const LabelWithRequired = ({ label }: { label: string }) => {
  return (
    <div className='mb-[4px] flex items-center leading-6'>
      <label htmlFor='name' className='text-[13px] font-bold text-black-600'>
        {label}
      </label>
      <span className='ml-2 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-bold text-red-500'>
        必須
      </span>
    </div>
  );
};

function EditModal(props: EditModalProps) {
  const { error } = useToast();
  const [user, setUser] = useRecoilState(userSelector);

  const schema = yup.object({
    first_name: yup.string().required("名は必須入力項目です"),
    last_name: yup.string().required("姓は必須入力項目です"),
    first_name_kana: yup
      .string()
      .required("メイは必須項目です")
      .test("katakana-checker", "カタカナで入力してください。", (value: string) => !!value.match(/^[ァ-ヶー]*$/)),
    last_name_kana: yup
      .string()
      .required("セイは必須入力項目です")
      .test("katakana-checker", "カタカナで入力してください。", (value: string) => !!value.match(/^[ァ-ヶー]*$/)),
    phone_number: yup
      .string()
      .required("電話番号は必須入力項目です")
      .matches(/^0\d{9,10}$/, "有効な電話番号を入力してください。"),
    gender: yup.number().required("性別は必須選択項目です"),
    birthday: yup.string().required("生年月日は必須入力項目です"),
    postal_code: yup
      .string()
      .required("郵便番号は必須入力項目です")
      .matches(/^\d{7}$/, "郵便番号は半角数字の7桁で入力してください。"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Form>({
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      first_name_kana: user.first_name_kana,
      last_name_kana: user.last_name_kana,
      phone_number: user.phone_number,
      gender: user.gender as number,
      birthday: user.birthday as string,
      postal_code: user.postal_code,
    },
  });

  const formValues = watch();

  const onSubmit: SubmitHandler<Form> = async (params) => {
    const res = await apiPatch("/api/user/me", params);
    if (res.code === 200) {
      setUser((prevState: User) => Object.assign({}, prevState, params));
      props.setIsEditModalOpen(false);
    }

    res.code === 422 && res.errors && error("登録に失敗しました");
  };

  const closeModal = () => {
    props.setIsEditModalOpen(false);
  };

  const handleGenderButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    const menButton = document.getElementById("men");
    const womenButton = document.getElementById("women");
    const elseButton = document.getElementById("else");
    const gender = Number(event.currentTarget.value);

    menButton?.classList.toggle("bg-blue-700", gender === 1);
    menButton?.classList.toggle("text-white", gender === 1);
    womenButton?.classList.toggle("bg-blue-700", gender === 2);
    womenButton?.classList.toggle("text-white", gender === 2);
    elseButton?.classList.toggle("bg-blue-700", gender === 3);
    elseButton?.classList.toggle("text-white", gender === 3);

    register("gender", { value: gender });
  };

  useEffect(() => {
    const fakeEvent = {
      currentTarget: {
        value: "1",
      },
    };
    handleGenderButton(fakeEvent as React.MouseEvent<HTMLButtonElement>);
  }, []);

  return (
    <div>
      <div
        className='fixed left-0 top-0 z-10 block h-full w-full cursor-pointer bg-modal-overlay'
        onClick={closeModal}
      />
      <div className='absolute left-1/2 z-10 h-fit -translate-x-1/2 -translate-y-1/2 transform overflow-x-hidden rounded-md bg-white p-8 sm:top-[480px] sm:w-[300px] sm:px-4 md:top-1/2 md:w-[559px] md:px-8'>
        <div className='relative'>
          <img
            src='/images/x-mark-blue.svg'
            alt=''
            className='absolute right-0 h-7 w-7 cursor-pointer'
            onClick={closeModal}
          ></img>
        </div>
        <h1 className='sm:text-16 mt-[28px] text-center font-bold md:text-[20px]'>プロフィールを編集する</h1>
        <div className='w-full'>
          <LabelWithRequired label='氏名' />
          <div className='form-group mb-2 flex'>
            <div className='mb-2 mr-1'>
              <label htmlFor='last-name' className='text-[13px] font-bold'>
                姓
              </label>
              <input
                type='text'
                className='border-grey-light block h-10 w-full  rounded border p-3 sm:w-[129px] md:w-[239.5px]'
                {...register("last_name")}
              />
              <span className=' mt-2 text-red-500 sm:text-[10px] md:text-[12px]'>{errors.last_name?.message}</span>
            </div>
            <div className='ml-1'>
              <label htmlFor='first-name' className='text-[13px] font-bold'>
                名
              </label>
              <input
                type='text'
                className='border-grey-light block h-10 w-full rounded border p-3 sm:w-[129px] md:w-[239.5px]'
                {...register("first_name")}
              />
              <span className=' mt-2 text-red-500 sm:text-[10px] md:text-[12px]'>{errors.first_name?.message}</span>
            </div>
          </div>

          <div className='form-group mb-4 flex'>
            <div className='mb-2 mr-1'>
              <label htmlFor='last-name-kana' className='text-[13px] font-bold'>
                セイ
              </label>
              <input
                type='text'
                className='border-grey-light block h-10 w-full rounded border p-3 sm:w-[129px] md:w-[239.5px]'
                {...register("last_name_kana")}
              />
              <span className=' mt-2 text-red-500 sm:text-[10px] md:text-[12px]'>
                {errors.first_name_kana?.message}
              </span>
            </div>
            <div className='ml-1'>
              <label htmlFor='first-name-kana' className='text-[13px] font-bold'>
                メイ
              </label>
              <input
                type='text'
                className='border-grey-light block h-10 w-full rounded border p-3 sm:w-[129px] md:w-[239.5px]'
                {...register("first_name_kana")}
              />
              <span className=' mt-2 text-red-500 sm:text-[10px] md:text-[12px]'>
                {errors.first_name_kana?.message}
              </span>
            </div>
          </div>

          <div className='form-group mb-6'>
            <div className='mb-2 mr-1'>
              <LabelWithRequired label='電話番号' />
              <input
                type='text'
                className='border-grey-light mb-1 block h-10 rounded border p-3 sm:w-[268px] md:w-[336px]'
                {...register("phone_number")}
              />
              <div className='flex flex-col'>
                <span className='mb-2 text-[13px] leading-5 text-gray-500'>
                  半角数字、ハイフンなしで入力してください
                </span>
                <span className=' mt-2 text-red-500 sm:text-[10px] md:text-[12px]'>{errors.phone_number?.message}</span>{" "}
              </div>
            </div>
          </div>

          <div className='form-group mb-6'>
            <LabelWithRequired label='性別' />
            <div className='mt-2 flex gap-1 sm:flex-col md:flex-row'>
              <button
                id='men'
                className='h-10 justify-center rounded border border-blue-800 px-3.5 text-center text-sm font-bold text-blue-800 sm:w-full md:w-32'
                onClick={handleGenderButton}
                value={1}
              >
                男性
              </button>
              <button
                id='women'
                className='h-10 justify-center rounded border border-blue-800 px-3.5 text-center text-sm font-bold text-blue-800 sm:w-full md:w-32'
                onClick={handleGenderButton}
                value={2}
              >
                女性
              </button>
              <button
                id='else'
                className='h-10 justify-center rounded border border-blue-800 px-3.5 text-center text-sm font-bold text-blue-800 sm:w-full md:w-32 '
                onClick={handleGenderButton}
                value={3}
              >
                その他
              </button>
            </div>
            <span className=' mt-2 text-red-500 sm:text-[10px] md:text-[12px]'>{errors.gender?.message}</span>
          </div>

          <div className='form-group mb-4'>
            <div className='mb-2 mr-1'>
              <LabelWithRequired label='生年月日' />
              <input
                min='1900-01-01'
                max='2100-12-31'
                type='date'
                className={`border-grey-light block h-10 w-[160px] rounded border p-3 ${
                  formValues.birthday ? "text-black" : "text-gray-500"
                }`}
                {...register("birthday")}
              />
              <span className=' mt-2 text-red-500 sm:text-[10px] md:text-[12px]'>{errors.birthday?.message}</span>
            </div>
          </div>

          <div className='form-group mb-4'>
            <div className='mb-2 mr-1'>
              <LabelWithRequired label='郵便番号' />
              <input
                type='text'
                className='border-grey-light block h-10 w-[160px] rounded border p-3'
                {...register("postal_code")}
              />
              <div className='flex flex-col'>
                <span className='mb-2 mt-1 text-[13px] leading-5 text-gray-500'>ハイフンなしで入力してください</span>
                <span className=' text-red-500 sm:text-[10px] md:text-[12px]'>{errors.postal_code?.message}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-2 flex justify-center'>
          <button
            type='button'
            className='rounded-md bg-blue-700 px-8 py-3 font-bold text-white hover:bg-blue-500 focus:outline-none sm:h-[40px] sm:w-[200px] sm:text-[13px] md:h-[64px] md:w-[280px] md:text-[18px]'
            onClick={handleSubmit(onSubmit)}
          >
            保存する
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditModal;
