import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useRecoilState } from "recoil";

import { locationState } from "@/stores/atoms/locationAtom";

const useControlBlowserBack = () => {
  const navigate = useNavigate();
  const [key] = useRecoilState(locationState);
  const location = useLocation();
  useEffect(() => {
    if (key.path !== "" && location.pathname !== key.path) {
      navigate(key.path);
    }
  }, []);
};
export default useControlBlowserBack;
